



















































































































































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import store from "@/store";

@Component({})
export default class ConfirmContract extends Vue {
  private loading = false;
  private data: any = null;
  private errors: any = null;

  mounted() {
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
    this.pay();
  }

  public async pay(): Promise<void> {
    this.loading = true;
    try {
      const res: AxiosResponse = await new UserApi().confirmContract(
        this.$route.params.uid
      );
      this.data = res.data;
    } catch (error: any) {
      this.errors = error.response.data;
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.loading = false;
    }
  }
}
